import { computed, ComputedRef, inject, Ref } from 'vue';
import parseDate from 'date-fns/parse';
import { OverlayEventDetail } from '@ionic/core';
import { Store } from '@/stores';
import {
    FbOrderExtraI,
    FbOrderI,
    FbOrderPieI,
} from '@/services/firebase/FirebaseService';
import { ApiService } from '@/services/api';
import showAlert from './Alert/useAlert';
import router from '@/router';

const client = new ApiService();

export interface ComputedOrderData {
    orderNumberFormatted: ComputedRef<string>;
    customerName: ComputedRef<string>;
    pies: ComputedRef<FbOrderPieI[]>;
    extras: ComputedRef<FbOrderExtraI[]>;
    itemsSummary: ComputedRef<string[]>;
    orderStatusFormatted: ComputedRef<string>;
    pickupTime: ComputedRef<Date>;
    pickupTimeFormatted: ComputedRef<string>;
    isLate: ComputedRef<boolean>;
    hasArrived: ComputedRef<boolean>;
    isReady: ComputedRef<boolean>;
    canConfirmHandoff: ComputedRef<boolean>;
    isCurbside: ComputedRef<boolean>;
    confirmHandoff(): Promise<boolean>;
}

// interface OrderSummaryI {
//     [key: string]: number;
// }

export default function useOrderData(
    order: ComputedRef<FbOrderI | undefined> | Ref<FbOrderI | undefined>
): ComputedOrderData {
    const store = inject('store') as Store;

    /**
     * Convert full customer name to just first name and last initial
     * @return string
     */
    const orderNumberFormatted = computed((): string => {
        if (!order.value) return '';
        return '#' + order.value.orderDetails?.orderNumber.replace(
            '(R)',
            '<span class="red">(R)</span>'
        );
    });

    /**
     * Convert full customer name to just first name and last initial
     * @return string
     */
    const customerName = computed((): string => {
        if (!order.value) return '';
        let name = `${order.value.orderDetails?.customerFirstName ?? ''}`;
        if (order.value.orderDetails?.customerLastName) {
            name += ` ${order.value.orderDetails.customerLastName[0]}.`;
        }
        return name;
    });

    /**
     * Filter out empty pies
     * @return FbOrderPieI[]
     */
    const pies = computed((): FbOrderPieI[] => {
        return order?.value?.pies?.filter(pie => !!pie) ?? [];
    });

    /**
     * Filter out empty extras
     * @return FbOrderExtraI[]
     */
    const extras = computed((): FbOrderExtraI[] => {
        const extras = order?.value?.extras?.filter(extra => !!extra) ?? [];
        extras.forEach(extra => {
            extra.modifiers = extra.modifiers?.filter(mod => !!mod) ?? [];
        });
        return extras;
    });

    /**
     * Summary of the order items - number of pies, drinks, etc
     * @return string
     */
    const itemsSummary = computed((): string[] => {
        if (!order.value) return [];
        const items = [] as string[];
        const piesCount = order.value.orderDetails.totalPies
        if (piesCount > 0) {
            items.push(`${piesCount} ${piesCount === 1 ? 'Pie' : 'Pies'} `);
        }

        const extrasCount = order?.value?.extras?.reduce((count, extra)=>{
            if (extra) count += extra.quantity;
            return count;
        }, 0);
        if (extrasCount > 0) {
            items.push(`${extrasCount} ${extrasCount === 1 ? 'Extra' : 'Extras'} `);
        }
        return items;
    });

    const confirmItemsSummary = computed((): string[] => {
            if (!order.value) return [];
            const items = [] as string[];
            const piesCount = order.value.orderDetails.totalPies
            if (piesCount > 0) {
                items.push(`${piesCount}x ${piesCount === 1 ? 'Pie' : 'Pies'} `);
            }

            if (order?.value?.extras) {
                const extrasSummary = order?.value?.extras?.reduce((items: {[key: string]: number}, item: FbOrderExtraI)=>{
                    items[item.name] = items[item.name] ?? 0;
                    items[item.name] += item.quantity;
                    return items;
                }, {} as {[key: string]: number})

                Object.keys(extrasSummary).forEach((name) => {
                    items.push(`${extrasSummary[name]}x ${name}`);
                })
            }
            return items;
        }
    );

    /**
     * Human-friendly translation of the order status string
     * @return string
     */
    const orderStatusFormatted = computed((): string => {
        const isDeliveryStatus: boolean = ['ready-for-delivery', 'out-for-delivery', 'delivered'].indexOf(order?.value?.orderDetails?.status as string) > -1;
        const status = isDeliveryStatus ? 'complete' : order?.value?.orderDetails?.status as string;
        return status.replace(/-|_/g, ' ') ?? '';
    });

    /**
     * Convert the pickup time string to a date object
     * @return Date
     */
    const pickupTime = computed(
        (): Date => {
            return parseDate(order?.value?.pickupTime ?? '', 'yyyy-MM-dd kk:mm:ss', new Date());
        }
    );

    /**
     * Pickup time in format `ETA 5:00 pm`
     * @return string
     */
    const pickupTimeFormatted = computed((): string => {
        return (
            'ETA ' +
            pickupTime.value.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            })
        );
    });

    /**
     * Flag specifying if the order is late
     * @return boolean
     */
    const isLate = computed((): boolean => {
        return pickupTime.value.getTime() < store.state.currentTime.getTime();
    });

    /**
     * Flag specifying if the customer has arrived
     * @return boolean
     */
    const hasArrived = computed((): boolean => {
        return !!order?.value?.orderDetails?.arrival_status?.arrived_at;
    });

    /**
     * Flag specifying if the order is ready for pickup
     * @return boolean
     */
    const isReady = computed((): boolean => {
        const readyStatuses = ['complete', 'ready-for-delivery', 'out-for-delivery', 'delivered'];
        return readyStatuses.includes(order?.value?.orderDetails?.status ?? '');
    });

    /**
     * Flag specifying if the order has already been handed off to the customer
     * @return boolean
     */
    const canConfirmHandoff = computed((): boolean => {
        // if acknowledge_handoff is defined/order completed, always hide cta
        const orderHandedOff = typeof order?.value?.orderDetails?.acknowledge_handoff != 'undefined';
        if (orderHandedOff) return false;

        const isDeliveryOrder = order?.value?.orderDetails.orderMethod == 'delivery';

        if (isDeliveryOrder) {
            console.log(order?.value?.orderDetails?.status);
            return order?.value?.orderDetails?.status === 'ready-for-delivery' || order?.value?.orderDetails?.status === 'out-for-delivery';
        }

        // allow confirming handoff once chit is printed/order is in current state
        return typeof order?.value?.chitsPrintTime !== 'undefined';
    });

    /**
     * Flag specifying if the order is a curbside pickup
     * @return boolean
     */
    const isCurbside = computed((): boolean => {
        return !!order?.value?.orderDetails?.arrival_status?.vehicle;
    });
    const handlecknowledgeHandoff = async (): Promise<boolean> => {
        const response = await client
        .post(`/orders/${order?.value?.orderDetails?.orderID}/acknowledge-handoff`)
        .catch(error => {
            const errors = error.response?.data?.data?.errors ?? {
                error: 'Could not reach the server. Please try again.',
            };
            showAlert({
                message: Object.values(errors).join('<br>'),
            });
            return false;
        });

        return !!response;
    }
    /**
     * Confirm to the API that the order has been handed off to the customer
     */
    const confirmHandoff = async (): Promise<boolean> => {
        if (!order.value) return false;

        if (!extras.value.length) {
            return handlecknowledgeHandoff();
        }
        const GOT_EM_ROLE = 'gotEm';
        const ORDER_DETAILS_ROLE = 'ViewOrderDetails';

        const message =`<div class="confirm-items-wrapper"><ul><li>
            ${confirmItemsSummary.value.join('</li><li>')}
        </ul></div>`;
        const buttons = [{
            text: 'Got ‘Em',
            role: GOT_EM_ROLE
        },{
            text: 'View order details',
            role: ORDER_DETAILS_ROLE
        }]
        const alertDissmiss: OverlayEventDetail = await showAlert({
            header: 'Don’t Forget!',
            subHeader: `This order contains more than just pies.`,
            message,
            buttons,
        });
        switch (alertDissmiss.role) {
            case GOT_EM_ROLE: return handlecknowledgeHandoff();
            case ORDER_DETAILS_ROLE:
                router.push(`/orders/${order?.value?.orderDetails?.orderKey}`);
            return true;
        }
        return false;
    };

    return {
        orderNumberFormatted,
        customerName,
        pies,
        extras,
        itemsSummary,
        orderStatusFormatted,
        pickupTime,
        pickupTimeFormatted,
        isLate,
        hasArrived,
        isReady,
        canConfirmHandoff,
        isCurbside,
        confirmHandoff,
    };
}
