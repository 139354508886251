
import { defineComponent, PropType, toRefs } from 'vue';
import { IonIcon } from '@ionic/vue';
import useConfigs from '@/services/config';
import { FbOrderI } from '@/services/firebase/FirebaseService';
import { hasOrderMessages } from '@/services/messages';
import useOrderData from '@/composables/useOrderData';
import ActionButton from '@/components/Buttons/ActionButton.vue';

interface OrderSummaryI {
    [key: string]: number;
}

export default defineComponent({
    name: 'OrdersTableRow',

    components: {
        ActionButton,
        IonIcon,
    },

    props: {
        order: {
            type: Object as PropType<FbOrderI>,
            required: true,
        },
    },

    setup(props) {
        const { order } = toRefs(props);

        return {
            configs: useConfigs(),
            hasOrderMessages: hasOrderMessages(order.value.orderDetails.orderKey),
            ...useOrderData(order),
        };
    },
});
