
import { defineComponent } from 'vue';
import { FbOrderI } from '@/services/firebase/FirebaseService';
import useConfigs from '@/services/config';
import OrdersTableRow from './OrdersTableRow.vue';

export default defineComponent({
    name: 'OrdersTable',

    components: {
        OrdersTableRow,
    },

    props: {
        title: {
            type: String,
            default: 'Orders',
        },
        orders: {
            type: Array as () => FbOrderI[],
            required: true,
        },
    },

    setup() {
        return {
            configs: useConfigs(),
        };
    },
    methods: {
        orderClicked(order: FbOrderI) {
            this.$router.push(`/orders/${order.orderDetails.orderKey}`);
            this.$emit('order-clicked', order);
        }
    }
});
