
import { defineComponent } from 'vue';
import { IonButton, IonIcon } from '@ionic/vue';

export default defineComponent({
    name: 'ActionButton',

    components: {
        IonButton,
        IonIcon,
    },

    props: {
        checkmark: Boolean,
        isLarge: Boolean,
        text: {
            type: String,
            required: true,
        },
    },
});
